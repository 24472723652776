/**
 * This function receives an array of functions that returns promises, executes
 * them in order and returns their results in an array.
 *
 * The `maxDepth` counter defines the maximum number of resolved promises to
 * execute. If `maxDepth` promises are resolved, the method will automatically
 * return. If `maxDepth` is set to `undefined` or is not provided, this feature
 * will be disabled.
 *
 * If a promise with index `i` is rejected, the returned array will contain the
 * error returned by the rejection of the promise in the position `i` and the
 * `maxDepth` counter will not be decreased.
 *
 * @template T - The type the promises returns when resolved.
 * @param {Array.<() => Promise.<T>>} promises - List of functions that return
 * promises to be resolved.
 * @param {number=} maxDepth - Maximum number of promises to execute, excluding
 * the ones that are rejected.
 * @returns {Promise.<Array.<T|Error>>} - An array with the values returned by
 * the promises in the same order. If a promise fails, its value will be the
 * error they return.
 */
export const resolvePromises = async (promises, maxDepth = undefined) => {
  if (maxDepth === 0 || promises.length === 0) return [];

  let res;
  let nextNumSteps = maxDepth;
  try {
    res = await promises[0]();
    if (nextNumSteps !== undefined) nextNumSteps = maxDepth - 1;
  } catch (e) {
    res = e;
  }

  const nextPromises = promises.slice(1);
  const resList = await resolvePromises(nextPromises, nextNumSteps);
  return [res, ...resList];
};
