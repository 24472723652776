// Get the user agent hints we needed and return along the old user agent.
const getUserAgentHints = () => {
  return navigator.userAgentData
    ?.getHighEntropyValues([
      "architecture",
      "bitness",
      "model",
      "platformVersion",
      "fullVersionList",
    ])
    .then((ua) => {
      const brands = [];
      ua.fullVersionList?.map((element) =>
        brands.push(element.brand + " - " + element.version)
      );
      return {
        deviceArchitecture: ua.architecture + ua.bitness,
        mobile: ua.mobile,
        deviceMobile: ua.model,
        OS: `${ua.platform} ${ua.platformVersion}`,
        browserBrands: brands,
      };
    });
};

// Returns the user agent.
export const getUserAgent = () => navigator.userAgent;

// Returns the client hints (new user agent).
export const getClientHints = () => {
  if (
    navigator.userAgent.match(/chrome|chromium|edg/i) &&
    navigator.userAgentData
  ) {
    return getUserAgentHints();
  }
  return Promise.resolve(undefined);
};
