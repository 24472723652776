import { standardResolutions } from "./constants";
import { calculateStatistics } from "../../utils/statistics";
import { resolvePromises } from "../../utils/promise";

/**
 * Returns an array with the available data of the video devices.
 * @param {HTMLVideoElement} video The video element in use.
 * @param {Array.<string> | undefined} cameraIds An array with the ids of the camera to be
 * analyzed. If not provided, then all the cameras will be analyzed.
 * @returns An array with the available data of the video devices.
 */
export const getAllCamsData = async (video, cameraIds) => {
  await requestCameraPermissions();
  if (!cameraIds) {
    cameraIds = await getAllCamsID();
  }
  const cameraDataPromises = cameraIds.map((id) => async () => {
    const camData = await getCamData(id);
    const resolutions = await getCamResolutions(id);
    const fps = await getFpsData(id, video);
    const startupBehavior = await getCamStartupData(id);
    if (camData) {
      return { ...camData, resolutions, startupBehavior, fps };
    }
  });
  return await resolvePromises(cameraDataPromises);
};

/** Requests for the permissions for all the video devices. */
const requestCameraPermissions = () =>
  navigator.mediaDevices
    .getUserMedia({ video: true })
    .then((stream) => stream.getTracks().forEach((track) => track.stop()))
    .catch((err) => console.error(err));

/** Returns an array with the ID of all the video devices */
const getAllCamsID = async () => {
  const devices = await navigator.mediaDevices.enumerateDevices();
  return devices
    .filter(({ kind }) => kind === "videoinput")
    .map((device) => device.deviceId);
};

/** Returns the data of the camera with the given id. If there is no camera available,
 *  returns `undefined`.*/
const getCamData = async (deviceId) => {
  if (!deviceId) return;

  // Get the video streams
  let stream;
  try {
    stream = await navigator.mediaDevices.getUserMedia({
      video: { deviceId: { exact: deviceId } },
    });
  } catch (err) {
    console.error(err);
  }
  if (!stream) return;

  const streamTrack = stream.getVideoTracks()[0];

  const supportGetCapabilities =
    typeof InstallTrigger === "undefined" && !document.documentMode;

  const camData = streamTrack
    ? {
        streamTrack: {
          streamTrack: streamTrack,
          enabled: streamTrack.enabled,
          id: streamTrack.id,
          kind: streamTrack.kind,
          label: streamTrack.label,
          muted: streamTrack.muted,
          readyState: streamTrack.readyState,
          remote: streamTrack.remote,
        },
        settings: streamTrack.getSettings(),
        capabilities: supportGetCapabilities
          ? streamTrack.getCapabilities()
          : "The browser does not support `streamTrack.getCapabilities()`",
        constraints: streamTrack.getConstraints(),
      }
    : undefined;
  streamTrack.stop();
  return camData;
};

const getCamResolutions = async (deviceId) => {
  let stream = null;
  try {
    stream = await navigator.mediaDevices.getUserMedia({
      video: { deviceId: { exact: deviceId } },
    });
  } catch (err) {
    console.error(err);
  }
  if (!stream) return;

  const resolutionsPromises = standardResolutions.map(
    (resolution) => async () => {
      const videoConstraints = {
        deviceId: { exact: deviceId },
        width: {
          exact: resolution.width,
        },
        height: {
          exact: resolution.height,
        },
        resizeMode: {
          exact: "none",
        },
      };

      const tracks = stream.getVideoTracks();

      const tracksPromises = tracks.map((track) => async () => {
        let valid = true;
        const start = performance.now();
        try {
          await track.applyConstraints(videoConstraints);
        } catch (e) {
          valid = false;
        }
        const end = performance.now();
        return { valid, start, end };
      });
      const res = await resolvePromises(tracksPromises);

      const valid = res[res.length - 1].valid;
      const time = res[res.length - 1].end - res[res.length - 1].start;
      return { resolution, valid, time };
    }
  );
  const cameraResolutions = await resolvePromises(resolutionsPromises);

  if (stream) {
    stream.getTracks().forEach((track) => {
      track.stop();
    });
  }

  return cameraResolutions;
};

const getCamStartupData = async (deviceId) => {
  const startupPromises = [...Array(20).keys()].map(() => async () => {
    try {
      const start = performance.now();
      const s = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: { exact: deviceId } },
      });
      const end = performance.now();
      s.getTracks().forEach((track) => {
        track.stop();
      });
      return end - start;
    } catch (e) {
      console.log(`Not supported: ${e}`);
    }
  });
  const startupTimes = (await resolvePromises(startupPromises)).filter(
    (time) => typeof time === "number"
  );

  const { mean, variance } = calculateStatistics(startupTimes);
  const standardDeviation = Math.sqrt(variance);

  return { startupTimes, mean, standardDeviation };
};

/**
 * The maximum number of recent timestamp values the system will store in
 * memory per resolution.
 *
 * This value will determine the number of frames used to calculate the value of
 * the current FPS. Higher values will make the measure more stable but less
 * likely to detect abrupt FPS changes in the stream; whereas lower values will
 * make the measure less stable but more likely to detect abrupt FPS changes in
 * the stream.
 */
const MAX_NUMBER_TS_STORED = 30;
/**
 * The number of FPS in the list returned by `getFpsData`.
 */
const NUM_FPS_STORED = 120;

/**
 * Returns fps data about the device with the given id. To calculate the FPS
 * live, a video instance must be provided, and will be used to run different
 * streams on it and determine the frame rate.
 *
 * @param {string} deviceId The device id of the device to analyze.
 * @param {Object} video A video instance that will be used to run different
 * streams and analyze their frame rates.
 * @returns {Object} Data about the FPS performance of the device with the given
 * ID running in different resolutions.
 */
const getFpsData = async (deviceId, video) => {
  // If the browser does not support the check, then skip it.
  // https://developer.mozilla.org/en-US/docs/Web/API/HTMLVideoElement#browser_compatibility
  if (!video.requestVideoFrameCallback) {
    return [];
  }
  if (!video) {
    throw new Error("No video reference provided for FPS analysis.");
  }

  const stream = await navigator.mediaDevices.getUserMedia({
    video: { deviceId: { exact: deviceId } },
  });
  video.srcObject = stream;

  const resolutionPromises = standardResolutions.map(
    (resolution) => async () => {
      const videoConstraints = {
        deviceId: { exact: deviceId },
        width: {
          exact: resolution.width,
        },
        height: {
          exact: resolution.height,
        },
        resizeMode: {
          exact: "none",
        },
      };

      const tracks = stream.getVideoTracks();
      const tracksPromises = tracks.map((track) => async () => {
        await track.applyConstraints(videoConstraints);

        const resolutionFpsData = await getResolutionFpsData(video);

        return {
          resolution,
          ...resolutionFpsData,
        };
      });
      return await resolvePromises(tracksPromises);
    }
  );
  const fpsData = (await resolvePromises(resolutionPromises))
    .flat(1)
    .filter((data) => !(data instanceof OverconstrainedError));

  stream.getTracks().forEach((track) => {
    track.stop();
  });
  video.srcObject = undefined;

  return fpsData;
};

const getResolutionFpsData = (currentVideoRef) => {
  return new Promise((resolve) => {
    const timestampsList = [];
    const fpsList = [];
    const update = (now) => {
      timestampsList.push(now);

      if (timestampsList.length >= MAX_NUMBER_TS_STORED) {
        timestampsList.shift();
      }

      const differences = timestampsList
        .map((_, i) => {
          return 1000 / (timestampsList[i] - timestampsList[i - 1]);
        })
        .filter(Boolean);
      const fps =
        differences.reduce((acc, current) => acc + current, 0) /
        differences.length;

      fpsList.push(fps);

      if (fpsList.length >= NUM_FPS_STORED + 1) {
        // Remove the first element because it is `null`.
        fpsList.shift();
        const fpsData = {
          fps: fpsList,
          length: fpsList.length,
          ...calculateStatistics(fpsList),
        };
        resolve(fpsData);
        return;
      }
      currentVideoRef.requestVideoFrameCallback(update);
    };
    currentVideoRef.requestVideoFrameCallback(update);
  });
};

/**
 * Returns a list with the labels and the ids of all the cameras.
 */
export const getBasicCameraData = async () => {
  await requestCameraPermissions();
  const cameraIds = await getAllCamsID();
  const cameraDataPromises = cameraIds.map((id) => async () => {
    const camData = await getCamData(id);
    return {
      id,
      label: camData.streamTrack.label,
    };
  });
  return await resolvePromises(cameraDataPromises);
};
