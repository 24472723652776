export function calculateStatistics(sample) {
  const update = (currentValue, newValue) => {
    let { count, mean, m2 } = currentValue;
    count++;
    const delta = newValue - mean;
    mean += delta / count;
    const delta2 = newValue - mean;
    m2 += delta * delta2;
    return { count, mean, m2 };
  };

  let variance = { count: 0, mean: 0, m2: 0 };

  for (let i = 0; i < sample.length; i++) {
    variance = update(variance, sample[i]);
  }

  return { mean: variance.mean, variance: variance.m2 / variance.count };
}
