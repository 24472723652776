// Resolution list was inspired by this application https://webcamtests.com/resolution.
export const standardResolutions = [
  {
    standard: "QQVGA",
    width: 160,
    height: 120,
  },
  {
    standard: "QCIF_176",
    width: 176,
    height: 144,
  },
  {
    standard: "QCIF_192",
    width: 192,
    height: 144,
  },
  {
    standard: "HQVGA",
    width: 240,
    height: 160,
  },
  {
    standard: "QVGA",
    width: 320,
    height: 240,
  },
  {
    standard: "Video CD NTSC",
    width: 352,
    height: 240,
  },
  {
    standard: "Video CD PAL",
    width: 352,
    height: 288,
  },
  {
    standard: "xCIF",
    width: 384,
    height: 288,
  },
  {
    standard: "360p",
    width: 480,
    height: 360,
  },
  {
    standard: "nHD",
    width: 640,
    height: 360,
  },
  {
    standard: "VGA",
    width: 640,
    height: 480,
  },
  {
    standard: "SD",
    width: 704,
    height: 480,
  },
  {
    standard: "DVD NTSC",
    width: 720,
    height: 480,
  },
  {
    standard: "WGA",
    width: 800,
    height: 480,
  },
  {
    standard: "SVGA",
    width: 800,
    height: 600,
  },
  {
    standard: "DVCPRO HD",
    width: 960,
    height: 720,
  },
  {
    standard: "XGA",
    width: 1024,
    height: 768,
  },
  {
    standard: "HD",
    width: 1280,
    height: 720,
  },
  {
    standard: "WXGA",
    width: 1280,
    height: 800,
  },
  {
    standard: "SXGA−",
    width: 1280,
    height: 960,
  },
  {
    standard: "SXGA",
    width: 1280,
    height: 1024,
  },
  {
    standard: "UXGA",
    width: 1600,
    height: 1200,
  },
  {
    standard: "FHD",
    width: 1920,
    height: 1080,
  },
  {
    standard: "QXGA",
    width: 2048,
    height: 1536,
  },
  {
    standard: "QSXGA",
    width: 2560,
    height: 2048,
  },
  {
    standard: "QUXGA",
    width: 3200,
    height: 2400,
  },
  {
    standard: "DCI 4K",
    width: 4096,
    height: 2160,
  },
  {
    standard: "HXGA",
    width: 4096,
    height: 3072,
  },
  {
    standard: "UW5K",
    width: 5120,
    height: 2160,
  },
  {
    standard: "5K",
    width: 5120,
    height: 2880,
  },
  {
    standard: "WHXGA",
    width: 5120,
    height: 3200,
  },
  {
    standard: "HSXGA",
    width: 5120,
    height: 4096,
  },
  {
    standard: "WHSXGA",
    width: 6400,
    height: 4096,
  },
  {
    standard: "HUXGA",
    width: 6400,
    height: 4800,
  },
  {
    standard: "8K UHD",
    width: 7680,
    height: 4320,
  },
  {
    standard: "WHUXGA",
    width: 7680,
    height: 4800,
  },
  {
    standard: "UW10K",
    width: 10240,
    height: 4320,
  },
];
