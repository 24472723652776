import React, { useEffect, useState } from "react";
import { Status, useApi } from "../../../functions/useApi/useApi";
import { GoogleButton } from "../Button/GoogleButton";
import { Alert } from "../../Alert/Alert";
import style from "./Login.module.scss";

const ERROR_MESSAGE =
  "An error occured while logging in. Please, ensure you have Internet connection and your credentials are valid";

/** Button with Google log in appearance which navigates to the given `loginURL` on click. */
export const Login = ({ loginURL }) => {
  const { data, error, status, call } = useApi(loginURL);
  const login = () => call();
  const [displayAlert, setDisplayAlert] = useState(false);

  useEffect(() => {
    if (error) {
      setDisplayAlert(true);
      return;
    }
    if (status === Status.Done && data) {
      window.location.href = data.url;
    }
  }, [data, status, error]);

  return (
    <div>
      <div className={style.login}>
        <p>Please, login with an account:</p>
        <br />
        <GoogleButton onClick={login} />
      </div>
      <br />
      {displayAlert && (
        <Alert title="Error while logging in" message={ERROR_MESSAGE} />
      )}
    </div>
  );
};
