import React, { useState } from "react";
import { Config } from "../../utils/config";
import { AuthRouter } from "../Auth/Router/AuthRouter";
import { BACKEND_URLS, ROUTER_PATHS } from "../../constants/constants";

const App = () => {
  const [authInfo, setAuthInfo] = useState();
  const [verifiedAccess, setVerifiedAccess] = useState(false);
  console.log("Starting metadata-collection-app...");

  return (
    <AuthRouter
      initialAuthInfo={{
        authInfo: authInfo,
        setAuthInfo: setAuthInfo,
        verifiedAccess: verifiedAccess,
        setVerifiedAccess: setVerifiedAccess,
        urls: {
          loginURL: `${Config.apiUrl}/${ROUTER_PATHS.LOGIN}`,
        },
      }}
      loginPath={`/${ROUTER_PATHS.LOGIN}`}
      loginURL={`${Config.apiUrl}/${BACKEND_URLS.OAUTH_INIT}`}
      callbackPath={`/${ROUTER_PATHS.CALLBACK}`}
      callbackURL={`${Config.apiUrl}/${BACKEND_URLS.OAUTH_CALLBACK}`}
      callOptions={{
        params: {
          token: {
            access_token: authInfo,
          },
        },
      }}
      formatData={(data) => data}
    />
  );
};

export default App;
