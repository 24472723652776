import React from "react";
import styles from "./DisplayData.module.scss";

export const DisplayData = ({ data }) => {
  return (
    data && (
      <div className={styles.displaydata}>
        <div>
          <p>
            Found <strong>{data?.metadata?.length || 0}</strong> video device(s)
          </p>
          <p>Data collected:</p>
        </div>
        <pre className={styles.displaydata__data}>
          {JSON.stringify(data, null, 4)}
        </pre>
      </div>
    )
  );
};
