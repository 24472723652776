import React, { useState, useRef } from "react";
import styles from "./DataCollection.module.scss";
import { useHistory, Redirect } from "react-router";
import { SelectDevices } from "./SelectDevices/SelectDevices";
import { MetadataClient } from "../../api/metadata";
import { Config } from "../../utils/config";
import { ROUTER_PATHS } from "../../constants/constants";
import { Footer } from "../Footer/Footer";
import { Alert } from "../Alert/Alert";
import { Title } from "../Title/Title";
import { getAllData } from "../../functions/get-data/get-all-data";
import Button, { BUTTON_SIZES } from "@yoti/react-components/button";
import { Panel } from "../Panel/Panel";
import { DisplayData } from "../DisplayData/DisplayData";
import { SwitchButtonBase } from "@yoti/ui-kit-components-react/switch-button";
import { Checkbox } from "../Checkbox/Checkbox";
import { Loading } from "@yoti/ui-kit-components-react/loading";

export const ERROR_MESSAGE = `Your data could not be collected. Please, ensure that all your 
                          devices are plugged in and you have Internet connection and try again.`;
const SELECT_DEVICES_REAL_DATA_MSG =
  "This is a list of all the video sources which are enabled on your device. Please, mark only those which belong to real cameras.";
const SELECT_DEVICES_FAKE_DATA_MSG =
  "This is a list of all the video sources which are enabled on your device. Please, mark only those which are virtual or fake.";

const metadataClient = new MetadataClient(Config.apiUrl);

/**
 * Transforms the given data into the format the BE expects.
 * @param {object} data Is the object with all the data obtained from the cameras.
 * @param {boolean} isFake If the data is fake or not.
 * @param {Array.<{id: string; description: string}>} descriptions The list of descriptions provided for the cameras and
 * their IDs.
 * @returns an object with the format expected by the backend with the given
 * data.
 */
const formatData = (data, isFake, descriptions) => {
  const metadata = data.metadata.map((entry) => {
    const descriptionArr = descriptions
      .filter(({ id }) => entry.constraints.deviceId.exact === id)
      .map(({ description }) => description);
    const description =
      descriptionArr?.length > 0 ? descriptionArr[0] : undefined;
    return { ...entry, description };
  });
  return { ...data, metadata, isFake };
};

export const DataCollection = () => {
  const history = useHistory(); // The navigation history object.
  const videoRef = useRef(null); // The reference to the video element.
  const [data, setData] = useState(); // The captured data.
  const [displayAlert, setDisplayAlert] = useState(false); // If the error alert is displayed.
  const [isRealData, setIsRealData] = useState(true); // If the data captured is real.
  const [isSelectingDevices, setSelectingDevices] = useState(false); // If the user is selecting the devices.
  const [isLoading, setIsLoading] = useState(false); // If the data is being processed.
  const [agrees, setAgrees] = useState(false); // If the user gives its consent to share its data.
  const [displayingData, setDisplayingData] = useState(false); // If the captured data is being displayed.

  const oauthData = history.location.state?.oauthData;

  const onClick = () => {
    setSelectingDevices(true);
  };

  const onShare = async (selectedDevicesData) => {
    setIsLoading(true);
    try {
      const ids = selectedDevicesData.map(({ id }) => id);
      const camData = await getAllData(videoRef.current, ids);
      const descriptions = selectedDevicesData.map(({ id, description }) => ({
        id,
        description,
      }));
      const dataToShare = formatData(camData, !isRealData, descriptions);
      setData(dataToShare);
      const insertedId = await metadataClient.insert(dataToShare, oauthData);
      setData((prev) => {
        return { databaseID: insertedId, ...prev };
      });
      setDisplayAlert(false);
      setSelectingDevices(false);
    } catch (err) {
      console.error(err);
      setDisplayAlert(true);
      setData(undefined);
    } finally {
      setIsLoading(false);
    }
  };

  if (!oauthData) {
    return <Redirect to={`/${ROUTER_PATHS.LOGIN}`} />;
  }

  return (
    <div className={styles.root}>
      <Title dataWasRetrieved={!!data && isSelectingDevices} />
      <br />
      {!isSelectingDevices ? (
        <div>
          <Panel>{!data ? <ConsentText /> : <ThankText />}</Panel>
          {!data && !isLoading && (
            <>
              <SelectDataType
                isRealData={isRealData}
                setIsRealData={setIsRealData}
              />
              <SelectAgree agrees={agrees} setAgrees={setAgrees} />
            </>
          )}
          <div className={styles.root__button}>
            <ShareButton
              hasShared={!!data}
              disabled={!agrees || isLoading}
              onClick={onClick}
              displayingData={displayingData}
              setDisplayingData={setDisplayingData}
            />
          </div>
          <div>{displayingData && <DisplayData data={data} />}</div>
        </div>
      ) : (
        <SelectDevices
          onShare={onShare}
          message={
            isRealData
              ? SELECT_DEVICES_REAL_DATA_MSG
              : SELECT_DEVICES_FAKE_DATA_MSG
          }
        />
      )}
      {isLoading && <Loading />}
      {displayAlert && (
        <Alert title="Error while sharing your data" message={ERROR_MESSAGE} />
      )}
      <video autoPlay ref={videoRef} />
      <Footer />
    </div>
  );
};

const ConsentText = () => (
  <div className={styles.root__terms}>
    <p>
      We are collecting your device metadata to improve our SICAP protections
      against virtual camera device attacks when using the Web face capture
      module.
    </p>
    <p>
      We need device metadata from a variety of devices. We will only use this
      data for this purpose and then it will be deleted once the testing is
      complete. We will only ever keep the data for 6 months. Metadata will be
      stored in a temporary database in our AI Services gCloud project.
    </p>
    <p>
      We may reach out to contact you by email to follow up on this testing, if
      you do not wish to be contacted please contact Miguel. If you want to
      withdraw your consent then please email Miguel at miguel.jimenez@yoti.com.
    </p>
    <p>
      <strong>NOTE: </strong> If you are going to perform Real data collection
      and are currently using a virtual camera service, please do not proceed
      until you disable it.
    </p>
    <p>
      Please, make sure <strong>your cameras are not being used</strong> by any
      other application or browser tab before starting the data collection.
    </p>
    <p>
      In the metadata collection process,{" "}
      <strong>the tool will open and display the camera preview</strong>, we
      need to do it in order to calculate the camera frame rate for all
      available resolutions.{" "}
      <strong>Important: No images are being saved.</strong>
    </p>
  </div>
);

const ThankText = () => (
  <p>Thank you so much for sharing your devices metadata.</p>
);

const SelectDataType = ({ isRealData, setIsRealData }) => (
  <>
    <p className={styles.data_type_text}>What type of data will you share?</p>
    <div className={styles.switch_container}>
      <div className={styles.switch}>
        <label>Fake</label>
        <SwitchButtonBase
          id="storybook-switch"
          checked={isRealData}
          onChange={() => setIsRealData((prev) => !prev)}
        />
        <label>Real</label>
      </div>
    </div>
  </>
);

const SelectAgree = ({ agrees, setAgrees }) => (
  <Checkbox
    label="I accept to share my data with Yoti"
    checked={agrees}
    onChange={() => setAgrees((prev) => !prev)}
  />
);

const ShareButton = ({
  hasShared,
  disabled,
  onClick,
  displayingData,
  setDisplayingData,
}) =>
  !hasShared ? (
    <Button
      onClick={onClick}
      size={BUTTON_SIZES.SMALL}
      fullWidth
      disabled={disabled}
    >
      Share data
    </Button>
  ) : (
    <Button
      onClick={() => setDisplayingData((prev) => !prev)}
      size={BUTTON_SIZES.SMALL}
      fullWidth
    >
      {!displayingData ? "Show" : "Hide"} shared data
    </Button>
  );
