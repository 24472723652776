import { getAllCamsData } from "./get-all-cams-data";
import { getUserAgent, getClientHints } from "./get-extra-data";

// Returns all the data we want to store.
export const getAllData = async (video, cameraIds) => {
  const metadata = await getAllCamsData(video, cameraIds);
  const userAgent = getUserAgent();
  const clientHints = await getClientHints();

  return {
    metadata,
    userAgent,
    clientHints,
  };
};
