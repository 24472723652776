import React from "react";
import styles from "./Title.module.scss";
import { TabHeader } from "@yoti/react-components/tabs";

const BEFORE_SHARE_TITLE =
  "Clicking Share Data means you confirm we can use this data for the small and discrete project described below.";
const AFTER_SHARE_TITLE = "Thank you for sharing your device metadata";

export const Title = ({ dataWasRetrieved }) => {
  return (
    <div className={styles.base__title}>
      <TabHeader
        isSelected
        onClick={() => {}}
        title={dataWasRetrieved ? AFTER_SHARE_TITLE : BEFORE_SHARE_TITLE}
      />
    </div>
  );
};
