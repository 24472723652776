import Axios from "axios";

export class ApiBase {
  constructor(url) {
    if (!url) {
      throw Error("URL cannot be empty");
    }
    if (!url.endsWith("/")) {
      url += "/";
    }
    this.url = url;
  }

  async fetch(url, init) {
    // Build url for current request. Use base url as root in case it
    // does not start with http or https.
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      if (url && url.startsWith("/")) {
        url = url.substr(1);
      }
      url = `${this.url}${url}`;
    }

    const res = await Axios(url, init);
    // Check response status to throw an error.
    if (res.status < 200 && res.status >= 300) {
      throw Error(res.statusText);
    }
    return res.data;
  }
}
