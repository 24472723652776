import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { createAuthContext } from "./AuthContext";
import { Route } from "./Route";
import { Login } from "../Login/Login";
import { CallbackRoute } from "../CallbackRoute";
import { DataCollection } from "../../DataCollection/DataCollection";

/** AuthRouter is the React router to be used when you wish to add OAuth to your app. */
export const AuthRouter = ({
  initialAuthInfo,
  loginPath,
  loginURL,
  callbackPath,
  callbackURL,
  formatData,
  children,
}) => {
  const AuthContext = createAuthContext(initialAuthInfo);

  return (
    <AuthContext.Provider value={initialAuthInfo}>
      <BrowserRouter>
        <Switch>
          <Route anonymous={true} exact path="/">
            <DataCollection />
          </Route>
          <Route anonymous={true} path={loginPath}>
            <Login loginURL={loginURL} />
          </Route>
          <Route
            anonymous={true}
            path={callbackPath}
            render={(props) => (
              <CallbackRoute
                callbackURL={callbackURL}
                formatData={formatData}
                search={props.location.search}
              />
            )}
          />
          {children}
        </Switch>
      </BrowserRouter>
    </AuthContext.Provider>
  );
};
