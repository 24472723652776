import React from "react";
import styles from "./Footer.module.scss";
import YotiPoweredIcon from "../../assets/Powered_by_Yoti.svg";

export const Footer = () => {
  return (
    <div className={styles.footer}>
      <img src={YotiPoweredIcon} alt="Powered by Yoti" />
    </div>
  );
};
