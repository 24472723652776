import React, { useContext } from "react";

let AuthContext = undefined;

/** Returns an AuthContext entity. If the entity was created before, it returns the
 *  same old one, otherwise, it creates and returns a new one.
 *
 *  [Singleton Patter Design] */
export const createAuthContext = (
  authInfo = {
    setAuthInfo: () => {},
    setVerifiedAccess: () => {},
    urls: {
      loginURL: "",
    },
  }
) => {
  if (!AuthContext) {
    AuthContext = React.createContext(authInfo);
  }
  return AuthContext;
};

/** Returns the Auth Context content. If there was no context created,
 *  it creates a new one.
 *
 *  [Singleton Patter Design] */
export const useAuthContext = () => {
  if (!AuthContext) {
    AuthContext = createAuthContext();
  }
  return useContext(AuthContext);
};

/** Resets the AuthContext value. */
export const resetAuthContext = () => {
  AuthContext = undefined;
};
