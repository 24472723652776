import React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { Status, useApi } from "../../functions/useApi/useApi";

/** CallbackHandler handles the callback with the server. It will render the child with
 *  the needed data. */
export const CallbackHandler = ({ callbackURL, callbackInfo, formatData }) => {
  const { call, data, status, error } = useApi(callbackURL);
  const history = useHistory();

  useEffect(() => {
    call({ params: callbackInfo });
  }, [call, callbackInfo]);

  useEffect(() => {
    if (error) {
      return;
    }
    if (status === Status.Done && data) {
      history.push({
        pathname: "/",
        state: { oauthData: formatData(data) },
      });
    }
  }, [data, error, formatData]);

  return status === Status.Done && data && <Redirect to="/" />;
};
