export const BACKEND_URLS = {
  OAUTH_INIT: "google",
  OAUTH_CALLBACK: "google/callback",
};

export const ROUTER_PATHS = {
  LOGIN: "login",
  CALLBACK: "callback",
  FAKE: "fake",
};
