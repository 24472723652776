import axios from "axios";
import { useCallback, useState } from "react";

export const Status = {
  Loading: "LOADING",
  Done: "DONE",
  Abort: "ABORT",
  Error: "ERROR",
};

export const useApi = (url) => {
  const [info, setInfo] = useState({
    data: undefined,
    error: undefined,
    status: undefined,
    cancel: () => {},
  });

  const callCallback = useCallback(
    (options = {}) => {
      const source = axios.CancelToken.source();
      setInfo({
        data: undefined,
        error: undefined,
        status: Status.Loading,
        cancel: source.cancel,
      });
      axios
        .request({ ...options, url: url, cancelToken: source.token })
        .then((response) =>
          setInfo({
            data: response.data,
            error: undefined,
            status: Status.Done,
            cancel: () => {},
          })
        )
        .catch((err) => {
          const wasAborted = axios.isCancel(err);
          wasAborted
            ? setInfo({
                data: undefined,
                error: undefined,
                status: Status.Abort,
                cancel: () => {},
              })
            : setInfo({
                data: undefined,
                error: err,
                status: Status.Error,
                cancel: () => {},
              });
        });
    },
    [setInfo, url]
  );

  return {
    data: info.data,
    error: info.error,
    status: info.status,
    abort: info.cancel,
    call: callCallback,
  };
};
