import React from "react";
import styles from "./Alert.module.scss";
import {
  Alert as YotiAlert,
  ALERT_SIZES,
  ALERT_TYPES,
} from "@yoti/react-components/alert";

export const Alert = ({ title, message }) => (
  <div className={styles.alert}>
    <YotiAlert
      size={ALERT_SIZES.LARGE}
      type={ALERT_TYPES.DANGER}
      icon
      heading={title}
    >
      {message}
    </YotiAlert>
  </div>
);
