import React from "react";
import styles from "./Checkbox.module.scss";
import { CheckboxBase } from "@yoti/react-components/checkbox";

export const Checkbox = ({ label, onChange, checked }) => {
  return (
    <div className={styles.checkbox}>
      <CheckboxBase label={label} onChange={onChange} checked={checked} />
    </div>
  );
};
