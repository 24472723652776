import React, { useEffect } from "react";
import { Route as RouteDOM } from "react-router-dom";
import { useAuthContext } from "./AuthContext";
import { Status, useApi } from "../../../functions/useApi/useApi";

/** A simple route to be used on the AuthRouter component.
 *  If the user is not authorized, it returns `null` value. */
export const Route = (props) => {
  const { anonymous, callOptions } = props;
  const {
    verifiedAccess,
    setVerifiedAccess,
    urls: { loginURL },
  } = useAuthContext();
  const { call, status, error } = useApi(loginURL);

  useEffect(() => {
    if (!anonymous && !verifiedAccess) {
      call(callOptions || {});
    }
  }, [anonymous, verifiedAccess, call, callOptions]);

  useEffect(() => {
    if (!anonymous && !verifiedAccess) {
      setVerifiedAccess(!error && status === Status.Done);
    }
  }, [anonymous, verifiedAccess, setVerifiedAccess, error, status]);

  return anonymous || verifiedAccess ? <RouteDOM {...props} /> : null;
};
