import { ApiBase } from "./base";

const MINIMUM_METADATA_FIELDS = 4

export class MetadataClient extends ApiBase {
  insert = (metadata, oauthData) => {
    //Check if each object from metadata field has the four minimum properties.
    if(metadata.metadata.some(metadataField => Object.keys(metadataField).length < MINIMUM_METADATA_FIELDS)){
      throw Error("The metadata camera object does not have the four minimum fields (streamTrack, settings, capabilities and constraints)");
    }
    return this.fetch("metadata", {
      method: "POST",
      data: JSON.stringify(metadata),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${oauthData}`,
      },
    });
  };
}
