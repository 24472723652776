import React, { useEffect, useState } from "react";
import styles from "./SelectDevices.module.scss";
import Button, { BUTTON_SIZES } from "@yoti/react-components/button";
import { Panel } from "../../Panel/Panel";
import { TextArea } from "@yoti/ui-kit-components-react/text-area";
import {
  DataTable,
  DataTableHeaders,
  DataTableRow,
} from "@yoti/ui-kit-components-react/data-table";
import { Checkbox } from "@yoti/ui-kit-components-react/checkbox";
import { getBasicCameraData } from "../../../functions/get-data/get-all-cams-data";
import { Loading } from "@yoti/ui-kit-components-react/loading";

const includesElement = (data, id) => data.some((element) => element.id === id);

const getElement = (data, id) => data.find((element) => element.id === id);

const addElement = (data, element) => [...data, element];

const removeElement = (data, id) => data.filter((element) => element.id !== id);

export const SelectDevices = ({ onShare, message }) => {
  // The basic data of the cameras.
  const [data, setData] = useState([]);
  // The data of the selected devices.
  const [selectedDevices, setSelectedDevices] = useState([]);

  // Get the basic data.
  useEffect(async () => {
    const data = await getBasicCameraData();
    setData(data);
  }, []);

  const handleCheckboxChange = (element) => {
    setSelectedDevices((prev) =>
      includesElement(prev, element.id)
        ? removeElement(prev, element.id)
        : addElement(prev, element)
    );
  };

  const handleDescriptionChange =
    (element) =>
    ({ target: { value: description } }) => {
      const newElement = {
        ...element,
        description,
      };
      const withNoElement = removeElement(selectedDevices, element.id);
      setSelectedDevices(addElement(withNoElement, newElement));
    };

  const handleShare = () => {
    const dataToShare = selectedDevices.map(({ id, description }) => ({
      id,
      description,
    }));
    onShare(dataToShare);
  };

  const getTableRows = () =>
    data.map((element) => {
      const { id, label } = element;
      return (
        <DataTableRow key={id}>
          <td>{label}</td>
          <td>
            <Checkbox
              checked={includesElement(selectedDevices, id)}
              onChange={() => handleCheckboxChange(element)}
              id={`${id}`}
            />
          </td>
          <td>
            <TextArea
              label="Description"
              value={getElement(selectedDevices, id)?.description || ""}
              onChange={handleDescriptionChange(
                getElement(selectedDevices, id)
              )}
              disabled={!includesElement(selectedDevices, id)}
            />
          </td>
        </DataTableRow>
      );
    });

  if (!data.length) {
    return <Loading />;
  }

  return (
    <div>
      <Panel>
        <div className={styles.text}>
          <p>{message}</p>
        </div>
        <DataTable>
          <DataTableHeaders>
            <th>Device name</th>
            <th>Share</th>
            <th>Description</th>
          </DataTableHeaders>
          {getTableRows()}
        </DataTable>
        <br />
      </Panel>
      <div className={styles.root__button}>
        <Button
          onClick={handleShare}
          disabled={selectedDevices.length === 0}
          size={BUTTON_SIZES.SMALL}
          fullWidth
        >
          Share data
        </Button>
      </div>
    </div>
  );
};
