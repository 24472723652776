import React from "react";
import { CallbackHandler } from "./CallbackHandler";

export const CallbackRoute = ({ search, callbackURL, formatData }) => {
  const urlParams = new URLSearchParams(search);
  const state = urlParams.get("state");
  const code = urlParams.get("code");
  return (
    <CallbackHandler
      callbackURL={callbackURL}
      callbackInfo={{
        state: state,
        code: code,
      }}
      formatData={formatData}
    ></CallbackHandler>
  );
};
